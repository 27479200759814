<template>
  <div class="add-creative">
    <div class="add-creative__content" v-loading="loading">
      <div>
        <div class="add-creative__title">
          Загрузить акт
        </div>
        <div
          class="form-input"
          :class="{
            'form-input--full' : form.CreativeGroupName,
            'form-input--error' : errors.CreativeGroupName
          }"
        >
          <input v-model="form.CreativeGroupName" type="text" required>
          <span class="form-input__label">Номер акта*</span>
          <template v-if="errors.CreativeGroupName && errors.CreativeGroupName.length">
            <div
              v-for="error in errors.CreativeGroupName"
              :key="error"
              class="form-input__error"
            >{{ error }}</div>
          </template>
        </div>
        <SingleDatePicker
          class="add-creative__date-picker form-input"
          title="Дата акта*"
          @changeDates="changeCreativeGroupStartDate"
        />
        <Selector
          ref="typeContract"
          class="add-creative__selector add-creative__input form-input"
          required
          :title="'Роль заказчика*'"
          :options="Object.keys(AdType).map((key) => ({ id: key, name: AdType[key] }))"
          :select-fitrst-item="false"
          :default-select-item="{id: 'Other', name: AdType['Other']}"
          disabled
          @handleChange="(val) => form.Type = val"
        />
        <Selector
          ref="typeContract"
          class="add-creative__selector add-creative__input form-input"
          required
          :title="'Роль исполнителя*'"
          :options="Object.keys(AdType).map((key) => ({ id: key, name: AdType[key] }))"
          :select-fitrst-item="false"
          :default-select-item="{id: 'Other', name: AdType['Other']}"
          disabled
          @handleChange="(val) => form.Type = val"
        />
        <div
          class="form-input"
          :class="{
            'form-input--full' : form.CreativeGroupName,
            'form-input--error' : errors.CreativeGroupName
          }"
        >
          <input v-model="form.CreativeGroupName" type="number" required>
          <span class="form-input__label">Общая стоимость услуг*</span>
          <template v-if="errors.CreativeGroupName && errors.CreativeGroupName.length">
            <div
              v-for="error in errors.CreativeGroupName"
              :key="error"
              class="form-input__error"
            >{{ error }}</div>
          </template>
        </div>
        <Selector
          ref="typeContract"
          class="add-creative__selector add-creative__input form-input"
          required
          :title="'Признак – с НДС/без НДС*'"
          :options="Object.keys(AdType).map((key) => ({ id: key, name: AdType[key] }))"
          :select-fitrst-item="false"
          :default-select-item="{id: 'Other', name: AdType['Other']}"
          disabled
          @handleChange="(val) => form.Type = val"
        />
        <div class="add-creative__date-picker-group form-input">
          <div class="add-creative__date-picker-wrapper">
            <SingleDatePicker
              class="add-creative__date-picker"
              title="Дата начала оказания услуг*"
              @changeDates="changeCreativeGroupStartDate"
            />
            <template v-if="errors.CreativeGroupStartDate && errors.CreativeGroupStartDate.length">
              <div
                v-for="error in errors.CreativeGroupStartDate"
                :key="error"
                class="form-input__error"
              >{{ error }}</div>
            </template>
          </div>    
          <div class="add-creative__date-picker-wrapper">
            <SingleDatePicker
              class="add-creative__date-picker"
              title="Дата окончания оказания услуг*"
              @changeDates="changeCreativeGroupEndDate"
            />
            <template v-if="errors.CreativeGroupEndDate && errors.CreativeGroupEndDate.length">
              <div
                v-for="error in errors.CreativeGroupEndDate"
                :key="error"
                class="form-input__error"
              >{{ error }}</div>
            </template>
          </div>
        </div>
      </div>
      <div class="add-creative__group-btns">
        <ButtonElement
          @click-button="createCreative"
          text="Сохранить"
          class="add-creative__save-btn"
          :disabled="isBtnDisabled"
        />
        <ButtonElement
          class="add-creative__cancel-btn button--empty"
          :text="'Отменить'"
          @click-button="closeDialog"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ButtonElement from "@/components/elements/ButtonElement";
import formValidate from "@/mixin/formValidate";
import Selector from "@/components/sort/Selector";
import numberWithSpaces from '@/mixin/numberWithSpaces'
import { ContractType, VatType, ContractVariant } from "@/utils/contracts";
import { AdType, AdForm } from "@/utils/creatives";
import SingleDatePicker from "@/components/sort/SingleDatePicker";
import fixDateFormat from '@/mixin/fixDate'

const form = () => ({
  CreativeGroupId: "",
  CreativeGroupName: "",
  CreativeGroupStartDate: new Date(),
  CreativeGroupEndDate: new Date(),
  IsSelfPromotion: true,
  Type: "",
  Form: "",
  AdvertiserUrls: [],
  Description: "",
  IsNative: false,
  IsSocial: false,
  TextData: [],
  OkvedCodes: [],

  NoTargetLinks: false,
})

export default {
  name: 'AddCreative',
  components: {
    ButtonElement,
    Selector,
    SingleDatePicker,
  },
  mixins: [fixDateFormat, formValidate, numberWithSpaces],
  data() {
    return {
      AdType,
      AdForm,
      ContractVariant,
      ContractType,
      VatType,
      form: form(),
      errors: {
        CreativeGroupName: "",
        CreativeGroupStartDate: new Date(),
        CreativeGroupEndDate: new Date(),
        IsSelfPromotion: true,
        Type: "",
        Form: "",
        AdvertiserUrls: [],
        Description: "",
        IsNative: false,
        IsSocial: false,
      },
      loading: false,
    }
  },
  computed: {
    isBtnDisabled() {
      return !(this.form.CreativeGroupName 
        || this.form.Form 
        || this.form.Description
        || this.form.TextData[0]?.TextData)
    }
  },
  created() {
  
  },
  methods: {
    createCreative() {
      this.loading = true 

      const payload = {
        CreativeGroupName: this.form.CreativeGroupName,
        CreativeGroupStartDate: this.fixDateFormatNumbers(this.form.CreativeGroupStartDate),
        CreativeGroupEndDate: this.fixDateFormatNumbers(this.form.CreativeGroupEndDate),
        IsSelfPromotion: this.form.IsSelfPromotion,
        Type: this.form.Type,
        Form: this.form.Form,
        AdvertiserUrls: this.form.AdvertiserUrls,
        Description: this.form.Description,
        IsNative: this.form.IsNative,
        IsSocial: this.form.IsSocial,
        TextData: this.form.TextData,
      }

      this.$store.dispatch('Creatives/createCreative', payload).then(() => {
        this.cleanData()
        this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: null })
        this.$notify({
          message: this.$t('new_app.success'),
          type: 'success'
        });
      }).catch((err) => {
        const objErrors = JSON.parse(err.data.errors.replace('Mediascout error request:', ''))
        
        objErrors.ErrorItems.map((errorItem) => {
          if (errorItem.PropertyName) {
            this.errors[errorItem.PropertyName] = [
              ...this.errors[errorItem.PropertyName] || [],
              errorItem.ErrorMessage,
            ]
          }

          this.$notify({
            message: errorItem.ErrorMessage,
            type: 'error'
          });
        })
      }).finally(() => {
        this.loading = false
      })
    },
    cleanData() {
      this.form = {...form()}
    },
    changeCreativeGroupStartDate(dateRange) {
      if (dateRange) {
        this.form.CreativeGroupStartDate = dateRange.startDate
      }
    },
    changeCreativeGroupEndDate(dateRange) {
      if (dateRange) {
        this.form.CreativeGroupEndDate = dateRange.startDate
      }
    },
    changeAdvertiserUrls(event) {
      this.form.AdvertiserUrls[0] = event.target.value
    },
    changeTextData(event) {
      this.form.TextData = [{
        TextData: event.target.value
      }]
    },
    changeOkvedCodes(event) {
      this.form.OkvedCodes = event.target.value.trim().split(',')
    },
    changeNoTargetLinks() {
      this.form.AdvertiserUrls = []
    },
    closeDialog() {}
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

.form-input--full .form-input__label {
    font-size: 1.2rem;
    line-height: 1.4rem;
    top: 0.6rem;
}

.add-creative {
  background: #fff;
  color: #000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__date-picker-group {
    display: flex;
    gap: 1.6rem;
  }

  &__reservation {
    padding-bottom: 3.2rem;
  }
  &__creative {
    padding-top: 3.2rem;
    border-top: .1rem solid #D1D4D8;
  }

  &__date-picker-wrapper {
    width: 100%;
  }

  &__date-picker {
    width: 100%;
    background: $basicWhite;
  }

  &__input-icon {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
  }

  &__group-btns {
    display: flex;
    gap: .8rem;
    margin-top: 4.8rem;
  }

  &__save-btn {
    width: 17.4rem;
  }

  &__cancel-btn {
    width: 13.7rem;
  }
  
  
  textarea {
    font-family: 'Arial';
  }

  &__title {
    font-weight: 700;
    font-size: 4rem;
    line-height: 4.7rem;
    margin-bottom: 2.3rem;
    color: #000;
  }

  &__sub-title {
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.3rem;
    margin-bottom: 2rem;
  }

  &__content {
    padding: 7rem;
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .selector__content {
    background: none;
    border: 1px solid #BFC2C6;
  }

  .selector__title {
    font-size: 1.6rem;
    color: #878B93;
  }
  &__input {
    .selector__content {
      width: 100%;
    }
  }

  .button {
    height: 5rem;
  }

  &__step {
    margin-bottom: 6rem;

    &:last-of-type {
      margin-bottom: 0;
    }

    &-title {
      font-weight: 700;
      font-size: 2.2rem;
      line-height: 2.4rem;
      color: #333333;
      margin-bottom: 2rem;
    }

    &-content {
      .form-input {
        width: 100%;
        max-width: 35rem;

        @include below($sm) {
          max-width: 100%;
        }
      }
    }

    &-error {
      color: $redLight;
      position: absolute;
      left: 6rem;
    }
  }
}

@include below(993px) {
  .add-creative__title{
    font-size: 22px;
    line-height: 1.3;
    margin-bottom: 2.3rem;
  }

  .add-creative__step{
    margin-bottom: 30px;
  }

  .add-creative__final,
  .add-creative__content{
    padding: 15px;
  }
}

@include below(769px) {
  .add-creative__final{
    .button{
      width: 100%;
      margin-top: 10px;
    }
  }
}
</style>
